/*
 * jQuery Accordion plugin new
 */
;(function(root, factory) {

    'use strict';
    if (typeof define === 'function' && define.amd) {
        define(['jquery'], factory);
    } else if (typeof exports === 'object') {
        module.exports = factory(require('jquery'));
    } else {
        root.SlideAccordion = factory(jQuery);
    }
}(this, function($) {

    'use strict';
    var accHiddenClass = 'js-acc-hidden';

    function SlideAccordion(options) {
        this.options = $.extend(true, {
            allowClickWhenExpanded: false,
            activeClass:'active',
            opener:'.opener',
            slider:'.slide',
            animSpeed: 300,
            collapsible:true,
            event: 'click',
            scrollToActiveItem: {
                enable: false,
                breakpoint: 767, // max-width
                animSpeed: 600,
                extraOffset: null
            }
        }, options);
        this.init();
    }

    SlideAccordion.prototype = {
        init: function() {
            if (this.options.holder) {
                this.findElements();
                this.setStateOnInit();
                this.attachEvents();
                this.makeCallback('onInit');
            }
        },

        findElements: function() {
            this.$holder = $(this.options.holder).data('SlideAccordion', this);
            this.$items = this.$holder.find(':has(' + this.options.slider + ')');
        },

        setStateOnInit: function() {
            var self = this;

            this.$items.each(function() {
                if (!$(this).hasClass(self.options.activeClass)) {
                    $(this).find(self.options.slider).addClass(accHiddenClass);
                }
            });
        },

        attachEvents: function() {
            var self = this;

            this.accordionToggle = function(e) {
                var $item = jQuery(this).closest(self.$items);
                var $actiItem = self.getActiveItem($item);

                if (!self.options.allowClickWhenExpanded || !$item.hasClass(self.options.activeClass)) {
                    e.preventDefault();
                    self.toggle($item, $actiItem);
                }
            };

            this.$items.on(this.options.event, this.options.opener, this.accordionToggle);
        },

        toggle: function($item, $prevItem) {
            if (!$item.hasClass(this.options.activeClass)) {
                this.show($item);
            } else if (this.options.collapsible) {
                this.hide($item);
            }

            if (!$item.is($prevItem) && $prevItem.length) {
                this.hide($prevItem);
            }

            this.makeCallback('beforeToggle');
        },

        show: function($item) {
            var $slider = $item.find(this.options.slider);

            $item.addClass(this.options.activeClass);
            $slider.stop().hide().removeClass(accHiddenClass).slideDown({
                duration: this.options.animSpeed,
                complete: function() {
                    $slider.removeAttr('style');
                    if (
                        this.options.scrollToActiveItem.enable &&
                        window.innerWidth <= this.options.scrollToActiveItem.breakpoint
                    ) {
                        this.goToItem($item);
                    }
                    this.makeCallback('onShow', $item);
                }.bind(this)
            });

            this.makeCallback('beforeShow', $item);
        },

        hide: function($item) {
            var $slider = $item.find(this.options.slider);

            $item.removeClass(this.options.activeClass);
            $slider.stop().show().slideUp({
                duration: this.options.animSpeed,
                complete: function() {
                    $slider.addClass(accHiddenClass);
                    $slider.removeAttr('style');
                    this.makeCallback('onHide', $item);
                }.bind(this)
            });

            this.makeCallback('beforeHide', $item);
        },

        goToItem: function($item) {
            var itemOffset = $item.offset().top;

            if (itemOffset < $(window).scrollTop()) {
                // handle extra offset
                if (typeof this.options.scrollToActiveItem.extraOffset === 'number') {
                    itemOffset -= this.options.scrollToActiveItem.extraOffset;
                } else if (typeof this.options.scrollToActiveItem.extraOffset === 'function') {
                    itemOffset -= this.options.scrollToActiveItem.extraOffset();
                }

                $('body, html').animate({
                    scrollTop: itemOffset
                }, this.options.scrollToActiveItem.animSpeed);
            }
        },

        getActiveItem: function($item) {
            return $item.siblings().filter('.' + this.options.activeClass);
        },

        makeCallback: function(name) {
            if (typeof this.options[name] === 'function') {
                var args = Array.prototype.slice.call(arguments);
                args.shift();
                this.options[name].apply(this, args);
            }
        },

        destroy: function() {
            this.$holder.removeData('SlideAccordion');
            this.$items.off(this.options.event, this.options.opener, this.accordionToggle);
            this.$items.removeClass(this.options.activeClass).each(function(i, item) {
                $(item).find(this.options.slider).removeAttr('style').removeClass(accHiddenClass);
            }.bind(this));
            this.makeCallback('onDestroy');
        }
    };

    $.fn.slideAccordion = function(opt) {
        var args = Array.prototype.slice.call(arguments);
        var method = args[0];

        return this.each(function() {
            var $holder = jQuery(this);
            var instance = $holder.data('SlideAccordion');

            if (typeof opt === 'object' || typeof opt === 'undefined') {
                new SlideAccordion($.extend(true, {
                    holder: this
                }, opt));
            } else if (typeof method === 'string' && instance) {
                if(typeof instance[method] === 'function') {
                    args.shift();
                    instance[method].apply(instance, args);
                }
            }
        });
    };

    (function() {
        var tabStyleSheet = $('<style type="text/css">')[0];
        var tabStyleRule = '.' + accHiddenClass;
        tabStyleRule += '{position:absolute !important;left:-9999px !important;top:-9999px !important;display:block !important; width: 100% !important;}';
        if (tabStyleSheet.styleSheet) {
            tabStyleSheet.styleSheet.cssText = tabStyleRule;
        } else {
            tabStyleSheet.appendChild(document.createTextNode(tabStyleRule));
        }
        $('head').append(tabStyleSheet);
    }());

    return SlideAccordion;
}));