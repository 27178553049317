require('./bootstrap');

jQuery.browser = {};
(function () {
    jQuery.browser.msie = false;
    jQuery.browser.version = 0;
    if (navigator.userAgent.match(/MSIE ([0-9]+)\./)) {
        jQuery.browser.msie = true;
        jQuery.browser.version = RegExp.$1;
    }
})();

/* jQuery document.ready */
jQuery(function() {
	initMobileNav();
	initOpenClose();
	initAccordion();
	initFancybox();
	initSlickCarousel();
	initTabs();
	//initDatepicker();
	initCustomForms();
});

// initialize custom form elements
function initCustomForms() {
	jcf.replaceAll();
}

function initDatepicker() {
	jQuery('input.datepicker').uiDatepicker({
		dateFormat: 'dd/mm/yy',
		firstDay: 1,
		changeMonth: true,
      	changeYear: true,
		yearRange: "1945:2021"
	});
}

// mobile menu init
function initMobileNav() {
	jQuery('body').mobileNav({
		menuActiveClass: 'nav-active',
		menuOpener: '.nav-opener'
	});
}

// open-close init
function initOpenClose() {
	jQuery('.open-close-box').openClose({
		activeClass: 'active',
		opener: '.opener-box',
		slider: '.slide-box',
		animSpeed: 400,
		effect: 'slide'
	});
}

// accordion menu init
function initAccordion() {
	jQuery('.accordion').slideAccordion({
		opener: '.opener',
		slider: '.slide',
		animSpeed: 300
	});

	ResponsiveHelper.addRange({
		'..767': {
			on: function() {
				jQuery('.mobile-accordion').slideAccordion({
					opener: '.opener-slide-mobile',
					slider: '.slide-mobile-accordion',
					animSpeed: 300
				});
			},
			off: function() {
				jQuery('.mobile-accordion').slideAccordion('destroy');
			}
		}
	});
}

// lightbox init
function initFancybox() {
	jQuery('a.btn-login').fancybox({
		parentEl: 'body',
		margin: [50, 0],
		loop: false,
		infobar: false,
		autoFocus: false,
		backFocus: false,
		slideShow: false,
		fullScreen: false,
		thumbs: false,
		toolbarCloseBtn: false,
		baseClass: 'login-lightbox'
	});
	jQuery('a.btn-lightbox').fancybox({
		parentEl: 'body',
		margin: [50, 0],
		loop: false,
		infobar: false,
		autoFocus: false,
		backFocus: false,
		slideShow: false,
		fullScreen: false,
		thumbs: false,
		toolbarCloseBtn: false,
		baseClass: 'login-lightbox'
	});
}

// slick init
function initSlickCarousel() {
	jQuery('.slider-for').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		fade: true,
		asNavFor: '.slider-nav'
	});
	jQuery('.slider-nav').slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		asNavFor: '.slider-for',
		dots: false,
		prevArrow: '<button class="slick-prev"><i class="icon-keyboard_arrow_left"></i></button>',
		nextArrow: '<button class="slick-next"><i class="icon-keyboard_arrow_right"></i></button>',
		focusOnSelect: true
	});
}

// content tabs init
function initTabs() {
	ResponsiveHelper.addRange({
		'768..': {
			on: function() {
				jQuery('.tabset').tabset({
					tabLinks: 'a',
					activeClass: 'active-tab',
					defaultTab: true
				});
			},
			off: function() {
				jQuery('.tabset').tabset('destroy');
			}
		}
	});
}