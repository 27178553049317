// jQuery
window.$ = window.jQuery = require('jquery');

// Slick Carousel
require('slick-carousel');

// Fancybox
require('@fancyapps/fancybox');

// JCF
// require('jcf/js/jcf');
// require('jcf/js/jcf.select');
// require('jcf/js/jcf.button');
// require('jcf/js/jcf.checkbox');
// require('jcf/js/jcf.file');
//require('jcf/js/jcf.number');
// require('jcf/js/jcf.radio');
// require('jcf/js/jcf.range');
// require('jcf/js/jcf.scrollable');
// require('jcf/js/jcf.textarea');

// Axios
// window.axios = require('axios');
// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Lodash
// import { _ } from 'lodash';

/*
Bootstarp modules
*/
// import offcanvas from 'bootstrap/js/dist/offcanvas'
// import alert from 'bootstrap/js/dist/alert'
// import button from 'bootstrap/js/dist/button'
// import carousel from 'bootstrap/js/dist/carousel'
// import collapse from 'bootstrap/js/dist/collapse'
// import dropdown from 'bootstrap/js/dist/dropdown'
// import modal from 'bootstrap/js/dist/modal'
// import popover from 'bootstrap/js/dist/popover'
// import scrollspy from 'bootstrap/js/dist/scrollspy'
// import tab from 'bootstrap/js/dist/tab'
// import toast from 'bootstrap/js/dist/toast'
// import tooltip from 'bootstrap/js/dist/tooltip'


/*
jQuery components
*/
 require('./jquery-components/accordion')
require('./jquery-components/tabs')
require('./jquery-components/burger-menu')
// require('./jquery-components/tooltip')
require('./jquery-components/open-close')
// require('./jquery-components/sticky-box')
// require('./jquery-components/smooth-scroll')
 require('./jquery-components/responsive-layout-helper')
 require('./jquery-components/datepicker')
  require('./jquery-components/jcf.number')